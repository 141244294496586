import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import CustomersHero from '../components/organisms/CustomersHero';
import CustomerStoriesGrid from '../components/organisms/CustomerStoriesGrid';

const CustomersPage = ({ data: { data, customerStories } }) => {
  const featuredStories = customerStories.edges
    .filter(({ node }) => node.customerStory.isFeatured)
    .slice(0, 4);

  return (
    <Layout>
      <Seo post={data} />

      <CustomersHero
        title={data.customers.title}
        body={data.customers.body}
        featuredStories={featuredStories.map(({ node }) => ({
          title: node.title,
          slug: node.slug,
          featuredImage: node.featuredImage?.node,
          logo: node.customerStory.logo,
        }))}
      />
      <CustomerStoriesGrid
        customerStories={customerStories.edges.map(({ node }) => ({
          title: node.title,
          slug: node.slug,
          featuredImage: node.featuredImage?.node,
          logo: node.customerStory.logo,
          solutions: node.customerStory.solutions,
          menuOrder: ((menuOrder) => menuOrder || 0)(node.menuOrder),
          industries: node.customerStory.industries.map(
            ({ industry }) => industry,
          ),
        }))}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query CustomersPageQuery {
    data: wpPage(slug: { eq: "customers" }) {
      title
      ...SEO
      customers {
        title
        body
      }
    }
    customerStories: allWpCustomerStory(
      sort: { fields: menuOrder, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          menuOrder
          featuredImage {
            node {
              ...Image
            }
          }
          customerStory {
            isFeatured
            industries {
              industry
            }
            solutions
            logo {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default CustomersPage;
