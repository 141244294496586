import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { IImage } from '../../interfaces/image';
import CtaLink from '../atoms/CtaLink';
import Image from '../base/Image';
import Ribbon from '../../../static/images/cus-ribbon.svg';

interface CustomersHeroProps {
  title: string;
  body: string;
  featuredStories: Array<{
    featuredImage: IImage;
    logo: IImage;
    title: string;
    slug: string;
  }>;
}

const CustomersHero: React.FC<CustomersHeroProps> = ({
  title,
  body,
  featuredStories,
}) => {
  const { heroBackground } = useStaticQuery(
    graphql`
      query {
        heroBackground: file(name: { eq: "customers-hero-bg" }) {
          ...FileImageBase64
        }
      }
    `,
  );
  return (
    <section className="relative py-16 text-white bg-cover lg:py-32">
      <img
        src={Ribbon}
        className="absolute top-0 right-0 z-10"
        alt=""
        style={{ maxWidth: '30%' }}
      />

      <div className="absolute inset-0 z-0">
        <Image
          loading="eager"
          data={{ localFile: heroBackground }}
          className="object-cover w-full h-full"
          objectFit="cover"
        />
      </div>
      <div className="relative z-10 u-container">
        <h1
          className="max-w-2xl u-h1"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className="max-w-lg mb-10 text-lg leading-snug md:text-xl md:mb-15">
          {body}
        </p>

        <Link
          to={`/case-studies/${featuredStories[0].slug}`}
          className="block mb-10 transition duration-200 bg-white lg:flex img-hover-zoom"
        >
          <div className="h-64 overflow-hidden bg-center bg-cover lg:w-1/2 md:min-h-550px bg-blue">
            <Image
              data={featuredStories[0].featuredImage}
              className="object-cover w-full h-full"
              objectFit="cover"
            />
          </div>
          <div className="flex flex-col justify-between p-8 lg:p-16 lg:w-1/2">
            <div>
              <img
                src={featuredStories[0].logo.sourceUrl}
                alt={featuredStories[0].logo.altText}
                className="w-auto h-8 mb-6 md:mb-12 md:h-11"
              />

              <h2
                className="mb-6 text-2xl font-semibold text-black md:mb-10 md:text-4xl"
                dangerouslySetInnerHTML={{ __html: featuredStories[0].title }}
              />
            </div>
            <CtaLink
              to={`/case-studies/${featuredStories[0].slug}`}
              label="Read Story"
              className="mt-auto"
              divOnly
            />
          </div>
        </Link>

        <div className="justify-between space-y-8 lg:flex lg:space-x-8 lg:space-y-0">
          {featuredStories.slice(1).map((story, index) => (
            <Link
              to={`/case-studies/${story.slug}`}
              key={index}
              className="flex flex-col justify-between flex-1 p-8 transition-opacity duration-200 bg-white rounded-sm shadow-xl md:p-12 hover:opacity-75"
            >
              <div>
                <img
                  src={story.logo.sourceUrl}
                  alt={story.logo.altText}
                  className="w-auto h-6 mb-6"
                />
                <h2 className="mb-4 leading-snug text-black u-h6">
                  {story.title}
                </h2>
              </div>
              <CtaLink
                to={`/case-studies/${story.slug}`}
                label="Read Story"
                divOnly
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomersHero;
