import { AnimatePresence, motion } from 'framer-motion';
import { graphql, Link, useStaticQuery } from 'gatsby';
import flatMap from 'lodash/flatMap';
import sort from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React, { useState } from 'react';
import { IImage } from '../../interfaces/image';
import CtaLink from '../atoms/CtaLink';
import Image from '../base/Image';

interface CustomerStoriesGridProps {
  customerStories: Array<{
    featuredImage: IImage;
    logo: IImage;
    title: string;
    slug: string;
    solutions: string[];
    industries: string[];
  }>;
}

const CustomerStoriesGrid: React.FC<CustomerStoriesGridProps> = ({
  customerStories,
}) => {
  const {
    wp: {
      options: {
        globalOptions: { customerStoryGridTitle, customersViewMore },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        options {
          globalOptions {
            customerStoryGridTitle
            customersViewMore
          }
        }
      }
    }
  `);

  const [industry, setIndustry] = useState('');
  const [solution, setSolution] = useState('');
  const [maxStories, setMaxStories] = useState(6);

  const solutions = sort(
    uniq(flatMap(customerStories, ({ solutions }) => solutions)),
  );
  const industries = sort(
    uniq(flatMap(customerStories, ({ industries }) => industries)),
  );

  const filteredCustomerStories = sort(customerStories.filter((customerStory) => {
    if (industry && !customerStory.industries.includes(industry)) {
      return false;
    }

    if (solution && !customerStory.solutions.includes(solution)) {
      return false;
    }

    return true;
  }), ({ menuOrder }) => menuOrder);

  console.log(filteredCustomerStories);


  return (
    <section className="my-16 md:my-32">
      <div className="u-container">
        <div className="items-center justify-between space-y-8 lg:flex lg:space-x-12 lg:space-y-0">
          <h2
            className="u-h3"
            dangerouslySetInnerHTML={{ __html: customerStoryGridTitle }}
          />

          <div className="items-center flex-shrink-0 space-y-5 lg:flex lg:space-x-5 lg:space-y-0">
            <div className="relative">
              <select
                className="w-full u-select md:w-auto"
                onChange={(e) => setIndustry(e.target.value)}
              >
                <option value="">Show all Industries</option>
                {industries.map((industry) => (
                  <option value={industry} key={industry}>
                    {industry}
                  </option>
                ))}
              </select>
            </div>

            <div className="relative">
              <select
                className="w-full u-select md:w-auto"
                onChange={(e) => setSolution(e.target.value)}
              >
                <option value="">Show all Solutions</option>
                {solutions.map((solution) => (
                  <option value={solution} key={solution}>
                    {solution}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-12 mt-16 md:grid-cols-2 lg:grid-cols-3">
          <AnimatePresence>
            {filteredCustomerStories.slice(0, maxStories).map((story) => (
              <motion.div
                key={story.slug}
                variants={{ off: { opacity: 0 }, on: { opacity: 1 } }}
                initial="off"
                animate="on"
                exit="off"
              >
                <Link
                  to={`/case-studies/${story.slug}`}
                  className="flex flex-col justify-between transition-opacity duration-200 hover:opacity-75"
                >
                  <div>
                    {story.featuredImage && (
                      <div className="relative mb-6">
                        <div className="relative aspect-w-16 aspect-h-11">
                          <div className="absolute inset-0">
                            <Image
                              data={story.featuredImage}
                              className="object-cover w-full h-full"
                              objectFit="cover"
                            />
                          </div>
                        </div>
                        <div className="absolute z-10 flex items-center justify-center min-h-0 py-3 bg-white rounded px-7 left-7 bottom-7 h-14 w-36">
                          <Image
                            data={story.logo}
                            className="flex-auto w-full h-auto max-h-full"
                            objectFit="contain"
                          />
                        </div>
                      </div>
                    )}
                    <h2 className="mb-4 leading-snug u-h6">{story.title}</h2>
                  </div>
                  <CtaLink
                    to={`/case-studies/${story.slug}`}
                    label="Read Story"
                    divOnly
                  />
                </Link>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        {maxStories < filteredCustomerStories.length && (
          <div className="mt-20 text-center">
            <button
              className="u-btn u-btn--primary"
              onClick={() => setMaxStories((max) => max + 6)}
            >
              {customersViewMore}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default CustomerStoriesGrid;
